<template>
  <b-card title="Supplier Returns">
    <supplier-return-filters @filtersUpdated="filtersChangeHandler" />
    <supplier-return-list :filters="filters" />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import SupplierReturnList from '@/components/SupplierReturn/SupplierReturnList.vue'
import SupplierReturnFilters from '@/components/SupplierReturn/SupplierReturnFilters.vue'

export default {
  components: {
    BCard,
    SupplierReturnList,
    SupplierReturnFilters,
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}
</script>
