<template>
  <b-modal
    v-model="showModal"
    title="Add Supplier Return"
    size="lg"
    class="m-4"
    no-close-on-backdrop

    @hidden="$emit('modal-closed')"
    @ok="submitHandler"
  >
    <b-form
      @submit.prevent="submitHandler"
    >
      <b-row>
        <b-col cols="12">
          <b-alert
            variant="danger"
            :show="errorMessage ? true : false"
          >
            <div
              v-if="typeof errorMessage === 'string'"
              class="alert-body"
            >
              <p>
                {{ errorMessage }}
              </p>
            </div>
            <div
              v-else-if="errorMessage.detail"
              class="alert-body"
            >
              <p>
                {{ errorMessage.detail }}
              </p>
            </div>
            <div
              v-else-if="errorMessage.non_field_errors"
              class="alert-body"
            >
              <p>
                {{ errorMessage.non_field_errors[0] }}
              </p>
            </div>
            <div
              v-else-if="errorMessage.message"
              class="alert-body"
            >
              <p>
                {{ errorMessage.message }}
              </p>
            </div>
          </b-alert>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Challan Number"
            label-for="challan-number"
          >
            <b-form-input
              id="challan-number"
              v-model="challanNumber"
              type="text"
              placeholder="Enter Challan Number"
              :class="errorMessage && errorMessage.challan_number && errorMessage.challan_number.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.challan_number"
              class="error-message"
            >
              {{ errorMessage.challan_number[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <label for="challan-date">Challan Date</label>
          <b-form-datepicker
            id="challan-date"
            v-model="challanDate"
            menu-class="w-100"
            calendar-width="100%"
            :class="errorMessage && errorMessage.challan_date && errorMessage.challan_date.length > 0 ? 'is-invalid':null"
          />
          <p
            v-if="errorMessage && errorMessage.challan_date"
            class="error-message"
          >
            {{ errorMessage.challan_date[0] }}
          </p>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Company Group"
            label-for="compnayGroup"
          >
            <custom-v-select
              id="compnayGroup"
              v-model="selectedCompanyGroup"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="companyGroupOptions"
              placeholder="Select Company Group"
              label="text"
              :class="errorMessage && errorMessage.company_group && errorMessage.company_group.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.company_group"
              class="error-message"
            >
              {{ errorMessage.company_group[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Supplier"
            label-for="supplier"
          >
            <custom-v-select
              id="Supplier"
              v-model="selectedSupplier"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="supplierOptions"
              label="text"
              placeholder="Select Supplier"
              :class="errorMessage && errorMessage.supplier && errorMessage.supplier.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.supplier"
              class="error-message"
            >
              {{ errorMessage.supplier[0] }}
            </p>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="6">
          <b-form-group
            label="PDF File"
            label-for="pdf-file"
          >
            <b-form-file
              id="pdf-file"
              v-model="pdfFile"
              accept=".pdf"
              :class="errorMessage && errorMessage.pdf_file && errorMessage.pdf_file.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.pdf_file"
              class="error-message"
            >
              {{ errorMessage.pdf_file[0] }}
            </p>
          </b-form-group>
        </b-col> -->
        <b-col cols="6">
          <b-form-group
            label="Return Type"
            label-for="return-type"
          >
            <custom-v-select
              id="return-type"
              v-model="selectedReturnType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="returnTypeOptions"
              label="text"
              placeholder="Select Return Type"
              :class="errorMessage && errorMessage.return_type && errorMessage.return_type.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.return_type"
              class="error-message"
            >
              {{ errorMessage.return_type[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Total Amount"
            label-for="amount"
          >
            <b-form-input
              id="amount"
              v-model="totalAmount"
              type="number"
              placeholder="Total Amount"
              :class="errorMessage && errorMessage.total_amount && errorMessage.total_amount.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.total_amount"
              class="error-message"
            >
              {{ errorMessage.total_amount[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Total Items"
            label-for="total-items"
          >
            <b-form-input
              id="total-items"
              v-model="totalItems"
              type="number"
              placeholder="Total Items"
              :class="errorMessage && errorMessage.total_items && errorMessage.total_items.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.total_items"
              class="error-message"
            >
              {{ errorMessage.total_items[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <label for="datepicker-full-width">Dispatch Date</label>
          <b-form-datepicker
            id="datepicker-full-width"
            v-model="dispatchDate"
            menu-class="w-100"
            calendar-width="100%"
            :class="errorMessage && errorMessage.dispatch_date && errorMessage.dispatch_date.length > 0 ? 'is-invalid':null"
          />
          <p
            v-if="errorMessage && errorMessage.dispatch_date"
            class="error-message"
          >
            {{ errorMessage.dispatch_date[0] }}
          </p>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Dispatch Courier"
            label-for="dispatch-courier"
          >
            <custom-v-select
              id="dispatch-courier"
              v-model="selectedDispatchCourier"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dispatchCourierOptions"
              label="text"
              placeholder="Select Courier"
              :class="errorMessage && errorMessage.dispatch_courier && errorMessage.dispatch_courier.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.dispatch_courier"
              class="error-message"
            >
              {{ errorMessage.dispatch_courier[0] }}
            </p>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="6">
          <b-form-group
            label="Docket Number"
            label-for="docket-number"
          >
            <b-form-input
              id="docket-number"
              v-model="docketNumber"
              type="text"
              placeholder="Enter Docket Number"
              :class="errorMessage && errorMessage.docket_number && errorMessage.docket_number.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.docket_number"
              class="error-message"
            >
              {{ errorMessage.docket_number[0] }}
            </p>
          </b-form-group>
        </b-col> -->
        <b-col cols="6">
          <b-form-group
            label="Number of Packages"
            label-for="number-of-packages"
          >
            <custom-v-select
              id="dispatch-courier"
              v-model="selectedNumberOfPackages"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="numberOfPackagesOptions"
              label="text"
              placeholder="Select Number of Packages"
              :class="errorMessage && errorMessage.number_of_packages && errorMessage.number_of_packages.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.number_of_packages"
              class="error-message"
            >
              {{ errorMessage.number_of_packages[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Status"
            label-for="status"
          >
            <custom-v-select
              id="status"
              v-model="selectedStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              label="text"
              placeholder="Select Status"
              :class="errorMessage && errorMessage.status && errorMessage.status.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.status"
              class="error-message"
            >
              {{ errorMessage.status[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <label for="datepicker-full-width-1">Closed On</label>
          <b-form-datepicker
            id="datepicker-full-width-1"
            v-model="closedOn"
            menu-class="w-100"
            calendar-width="100%"
            :class="errorMessage && errorMessage.closed_on && errorMessage.closed_on.length > 0 ? 'is-invalid':null"
          />
          <p
            v-if="errorMessage && errorMessage.closed_on"
            class="error-message"
          >
            {{ errorMessage.closed_on[0] }}
          </p>
        </b-col>
        <b-col cols="12">
          <label for="textarea-default">Remarks</label>
          <b-form-textarea
            id="textarea-default"
            v-model="remarks"
            rows="4"
            :class="errorMessage && errorMessage.remarks && errorMessage.remarks.length > 0 ? 'is-invalid':null"
          />
          <p
            v-if="errorMessage && errorMessage.remarks"
            class="error-message"
          >
            {{ errorMessage.remarks[0] }}
          </p>
        </b-col>
      </b-row>

    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="saving"
        @click="ok()"
      >
        Create
        <b-spinner
          v-if="saving"
          small
          label="Small Spinner"
        />
      </b-button>

    </template>
  </b-modal>

</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BButton, BFormInput, BFormTextarea, BFormDatepicker, BForm, BSpinner, BAlert,
} from 'bootstrap-vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    CustomVSelect,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormDatepicker,
    BFormTextarea,
    BButton,
    BSpinner,
    BAlert,
  },

  data() {
    return {
      showModal: true,
      challanNumber: null,
      challanDate: null,
      selectedCompanyGroup: null,
      companyGroupOptions: [],
      selectedSupplier: null,
      supplierOptions: [],
      selectedReturnType: null,
      returnTypeOptions: [
        { text: 'Breakage / Expiry', value: 'Breakage / Expiry' },
        { text: 'Purchase Return', value: 'Purchase Return' },
      ],
      selectedStatus: null,
      statusOptions: [
        { text: 'Open', value: 'Open' },
        { text: 'Partially Closed', value: 'Partially Closed' },
        { text: 'Closed', value: 'Closed' },
      ],
      totalAmount: null,
      totalItems: null,
      dispatchDate: null,
      dispatchCourierOptions: [],
      selectedDispatchCourier: null,
      selectedNumberOfPackages: null,
      numberOfPackagesOptions: [
        { text: '0', value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
        { text: '4', value: '4' },
        { text: '5', value: '5' },
        { text: '6', value: '6' },
        { text: '7', value: '7' },
        { text: '8', value: '8' },
        { text: '9', value: '9' },
      ],
      closedOn: null,
      remarks: null,
      saving: false,
      errorMessage: {},
    }
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/company_group_options')
        .then(res => {
          this.companyGroupOptions = res.data.data
        })

      axios.get('/supplier_options')
        .then(res => {
          this.supplierOptions = res.data.data
        })

      axios.get('/returns/courier_options')
        .then(res => {
          this.dispatchCourierOptions = res.data.data
        })
    },
    submitHandler(event) {
      event.preventDefault()
      const formData = new FormData()
      if (this.challanNumber) {
        formData.append('challan_number', this.challanNumber)
      } if (this.challanDate) {
        formData.append('challan_date', this.challanDate)
      }
      if (this.selectedCompanyGroup) {
        formData.append('company_group', this.selectedCompanyGroup.id)
      }
      if (this.selectedSupplier) {
        formData.append('supplier', this.selectedSupplier.id)
      }
      if (this.selectedReturnType) {
        formData.append('return_type', this.selectedReturnType.value)
      }
      if (this.totalAmount) {
        formData.append('total_amount', this.totalAmount)
      }
      if (this.totalItems) {
        formData.append('total_items', this.totalItems)
      }
      if (this.dispatchDate) {
        formData.append('dispatch_date', this.dispatchDate)
      }
      if (this.selectedDispatchCourier) {
        formData.append('dispatch_courier', this.selectedDispatchCourier.id)
      }
      if (this.selectedNumberOfPackages) {
        formData.append('number_of_packages', this.selectedNumberOfPackages.value)
      }
      if (this.selectedStatus) {
        formData.append('status', this.selectedStatus.value)
      }
      if (this.closedOn) {
        formData.append('closed_on', this.closedOn)
      }
      if (this.remarks) {
        formData.append('remarks', this.remarks)
      }
      this.saving = true
      axios.post('/returns/supplier_return/', formData)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message || 'Supplier Return Created',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.$emit('item-added')
          this.saving = false
        }).catch(error => {
          this.errorMessage = error?.response?.data || 'Something went wrong'
          this.saving = false
        })
    },
  },
}

</script>

<style scoped>
.error-message {
  color: red
}
</style>
