<template>
  <b-modal
    v-model="modalShow"
    size="xl"
    scrollable
    title="Supplier Return Details"
    @hidden="$emit('modal-closed')"
  >

    <div
      v-if="fetchLoading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="fetchError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ fetchError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!fetchLoading && !fetchError">
      <b-table-simple
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Challan Number
            </b-th>
            <b-td>
              {{ itemDetails.challan_number }}
            </b-td>
            <b-th>
              Challan Date
            </b-th>
            <b-td>
              {{ itemDetails.challan_date && formatedDate(itemDetails.challan_date) }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Company Gorup
            </b-th>
            <b-td>
              {{ itemDetails.company_group__name }}
            </b-td>
            <b-th>
              Supplier
            </b-th>
            <b-td>
              {{ itemDetails.supplier__name }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Number of Packages
            </b-th>
            <b-td>
              {{ itemDetails.number_of_packages }}
            </b-td>
            <b-th>
              Retrun Type
            </b-th>
            <b-td>
              {{ itemDetails.return_type }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Total Amount
            </b-th>
            <b-td>
              {{ formateNumber(itemDetails.total_amount) }}
            </b-td>
            <b-th>
              Total Items
            </b-th>
            <b-td>
              {{ itemDetails.total_items }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Dispatch Date
            </b-th>
            <b-td>
              {{ formatedDate(itemDetails.dispatch_date) }}
            </b-td>
            <b-th>
              Dispatch Courier
            </b-th>
            <b-td>
              {{ itemDetails.dispatch_courier__name }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Remarks
            </b-th>
            <b-td>
              {{ itemDetails.remarks }}
            </b-td>
            <b-th>
              Created By
            </b-th>
            <b-td>
              {{ itemDetails.created_by__fullname }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              PDF File (Old)
            </b-th>
            <b-td>
              <a
                v-if="itemDetails.pdf_file"
                :href="itemDetails.pdf_file"
                target="_blank"
              >
                <span>View File</span>
              </a>
            </b-td>
            <b-th>
              Docket Number (Old)
            </b-th>
            <b-td>
              {{ itemDetails.docket_number }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-alert
        variant="danger"
        :show="updateError ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ updateError }}
          </p>
        </div>
      </b-alert>
      <b-table-simple
        class="mt-2"
        bordered
        fixed
      >
        <b-tbody>
          <b-tr>
            <b-th> Checked PDF File </b-th>
            <b-td>
              <b-form-group v-if="!itemDetails.checked_pdf_file">
                <b-form-file
                  v-model="formData.checkedPDF"
                  :disabled="updateLoading"
                  accept=".pdf"
                />

              </b-form-group>
              <a
                v-else
                :href="itemDetails.checked_pdf_file"
                target="_blank"
              >
                <span>View File</span>
              </a>
            </b-td>
          </b-tr>
          <b-tr>

            <b-th>
              Status
            </b-th>
            <b-td colspan="1">
              <custom-v-select
                v-model="formData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                label="text"
                :disabled="updateLoading"
                placeholder="Select Status"
                :clearable="false"
                :reduce="status => status.value"
              />
            </b-td>
            <b-th>
              Closed On
            </b-th>
            <b-td>
              <b-form-datepicker
                v-model="formData.closedOn"
                :disabled="updateLoading || status !== 'Closed'"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="primary"
        :disabled="updateLoading"
        @click="handleUpdate"
      >
        Update
        <b-spinner
          v-if="updateLoading"
          label="Small Spinner"
          small
        />
      </b-button>
    </div>
    <hr>

    <b-row>
      <b-col
        align="right"
      >
        <b-button
          variant="outline-primary"
          class="mb-1 ml-2"
          @click="showAddForm = true"
        >
          Add Supplier Credit Note
        </b-button>

      </b-col>
    </b-row>

    <add-supplier-credit-note
      v-if="showAddForm"
      :supplier-return-id="itemId"
      @cancelled="showAddForm = false"
      @item-added="showAddForm = false"
    />
    <supplier-credit-note-list
      v-else
      :supplier-return-id="itemId"
    />

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          class="float-right"
          @click="modalShow=false"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BAlert, BSpinner, BTableSimple, BTbody, BTd, BTr, BTh, BRow, BCol, BFormGroup, BFormFile, BFormDatepicker,
} from 'bootstrap-vue'
import axios from 'axios'
import dateMixin from '@/mixins/dateMixin'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formatMixin from '@/mixins/formatMixin'
import SupplierCreditNoteList from './SupplierCreditNoteList.vue'
import AddSupplierCreditNote from './AddSupplierCreditNote.vue'

export default {
  components: {
    BButton,
    BAlert,
    BSpinner,
    BTableSimple,
    BTbody,
    BTd,
    BTr,
    BTh,
    BRow,
    BCol,
    SupplierCreditNoteList,
    AddSupplierCreditNote,
    BFormGroup,
    BFormFile,
    CustomVSelect,
    BFormDatepicker,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      itemDetails: {},
      modalShow: true,
      fetchError: null,
      fetchLoading: true,
      showAddForm: false,
      statusOptions: [
        { text: 'Open', value: 'Open' },
        { text: 'Partially Closed', value: 'Partially Closed' },
        { text: 'Closed', value: 'Closed' },
      ],
      formData: {
        status: null,
        closedOn: '',
        checkedPDF: null,
      },
      updateLoading: false,
      updateError: null,
    }
  },
  computed: {
    status() {
      return this.formData.status
    },
  },
  watch: {
    status(newVal) {
      if (newVal !== 'Closed') {
        this.formData.closedOn = ''
      }
    },
  },
  created() {
    this.fetchDetails()
  },
  methods: {
    fetchDetails() {
      axios.get(`/returns/supplier_return/${this.itemId}`)
        .then(res => {
          this.itemDetails = res.data
          this.fetchError = null
          this.fetchLoading = false

          this.formData = {
            ...this.formData,
            status: this.itemDetails.status,
            closedOn: this.itemDetails.closed_on || '',
          }
        })
        .catch(error => {
          this.fetchError = error?.response?.data?.error || 'Something went wrong'
          this.fetchLoading = false
        })
    },
    async handleUpdate() {
      try {
        const {
          status,
          closedOn,
          checkedPDF,
        } = this.formData
        this.updateLoading = true
        this.updateError = null

        const payload = new FormData()
        if (checkedPDF) {
          payload.append('checked_pdf_file', checkedPDF)
        }
        payload.append('status', status)
        payload.append('closed_on', closedOn)

        await axios.patch(`/returns/supplier_return/${this.itemId}/`, payload)
        await this.fetchDetails()
        this.$emit('onUpdate')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data updated successfully!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.updateError = error?.response?.data?.non_field_errors?.[0] || 'Something Went wrong!'
      } finally {
        this.updateLoading = false
      }
    },
  },
}
</script>
