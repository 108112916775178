/**
 * Ex. value: 99999
 * return value: '99,999'
 */
export default {
  methods: {
    formateNumber(value) {
      if (typeof value === 'number') {
        return value.toLocaleString('en-IN')
      }
      if (typeof value === 'string') {
        const parsedValue = parseFloat(value)
        return Number.isNaN(parsedValue) ? value : this.formateNumber(parsedValue)
      }
      return value
    },
  },
}
