<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <add-supplier-return
      v-if="showAddForm"
      @modal-closed="showAddForm = false"
      @item-added="addItemHandler"
    />

    <b-row>
      <b-col
        align="right"
      >
        <b-button
          variant="outline-primary"
          class="mb-1 ml-2"
          @click="showAddForm = true"
        >
          Add Supplier Return
        </b-button>

      </b-col>
    </b-row>

    <supplier-return-details
      v-if="openItemId"
      :item-id="openItemId"
      @modal-closed="openItemId=null"
      @onUpdate="fetchItems"
    />

    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      @sort-changed="sortingChanged"
    >
      <template #cell(challan_date)="data">
        {{ data.item.challan_date && formatedDate(data.item.challan_date) }}
      </template>
      <template #cell(dispatch_date)="data">
        {{ formatedDate(data.item.dispatch_date) }}
      </template>
      <template #cell(status)="data">
        <status-icon :value="data.item.status" />
      </template>
      <template #cell(total_amount)="data">
        {{ formateNumber(data.item.total_amount) }}
      </template>
      <template #cell(actions)="data">
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="View Details"
          icon="EyeIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="displayDetails(data.item.id)"
        />

        <b-spinner
          v-if="sendEmailLoader && sendEmailId && sendEmailId === data.item.id"
          small
          label="Small Spinner"
        />
        <feather-icon
          v-else
          v-b-tooltip.hover="{boundary:'window'}"
          title="Send Email"
          icon="SendIcon"
          size="20"
          class="cursor-pointer"
          @click="sendEmail(data.item.id)"
        />
      </template>
    </b-table>

    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => {currentPage = page}"
    />

  </div>

</template>

<script>
import {
  BSpinner, BTable, BAlert, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'

import dateMixin from '@/mixins/dateMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formatMixin from '@/mixins/formatMixin'
import AddSupplierReturn from './AddSupplierReturn.vue'
import SupplierReturnDetails from './SupplierReturnDetails.vue'
import DetailedPagination from '../UI/DetailedPagination.vue'
import StatusIcon from '../UI/StatusIcon.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BRow,
    BCol,
    BButton,
    BAlert,
    AddSupplierReturn,
    SupplierReturnDetails,
    DetailedPagination,
    StatusIcon,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      results: [],
      tableFields: [
        { key: 'challan_number', label: 'Challan Number', sortable: true },
        { key: 'challan_date', label: 'Challan Date', sortable: true },
        { key: 'company_group', label: 'Company Group', sortable: true },
        { key: 'supplier', label: 'Supplier', sortable: true },
        { key: 'created_by__fullname', label: 'Created By', sortable: true },
        { key: 'total_items', label: 'Total Items', sortable: true },
        {
          key: 'total_amount', label: 'Total Amount', sortable: true, class: 'text-right pr-2',
        },
        { key: 'dispatch_date', label: 'Dispatch Date', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'challan_date',
      sortDesc: true,
      submitting: false,
      showAddForm: false,
      openItemId: null,
      sendEmailLoader: false,
      sendEmailId: null,
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.fetchItems()
      }
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    fetchItems() {
      this.loading = true
      axios.post('/returns/filtered_supplier_returns',
        {
          page: this.currentPage,
          per_page: this.perPage,
          sort_by: this.sortBy,
          sort_desc: this.sortDesc,
          filters: { ...this.filters },
        })
        .then(res => {
          this.results = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        }).catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
    addItemHandler() {
      this.showAddForm = false
      this.fetchItems()
    },
    displayDetails(itemId) {
      this.openItemId = itemId
    },
    async sendEmail(id) {
      try {
        this.sendEmailId = id
        this.sendEmailLoader = true

        const response = await axios.post(`/returns/send_supplier_return_email/${this.sendEmailId}/`)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.message || 'Email Sent Successfully!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.sendEmailId = null
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.message || 'Failed to sent email!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.sendEmailLoader = false
      }
    },
  },
}
</script>
