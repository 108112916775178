import moment from 'moment-timezone'

export default {
  methods: {
    formatedDate(dateString, format = 'DD-MM-YYYY') {
      return moment.utc(dateString).tz('Asia/Kolkata').format(format)
    },
    formatedTime(timeString, format = 'LT') {
      return moment.utc(timeString, 'h:mm:ss').format(format)
    },
  },
}
