<template>
  <div class="input-group">
    <flat-pickr
      v-model="date"
      class="form-control"
      :placeholder="placeholder"
      :config="config"
      @on-change="onChange"
    />
    <div
      v-if="date"
      class="input-group-append"
    >
      <BButton
        variant="outline-secondary"
        class="px-1 flat-picker-clear-btn"
        @click="onDateClear"
      >
        <feather-icon icon="XIcon" />
      </BButton>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    flatPickr,
    BButton,
  },
  props: {
    value: {
      type: [String, Object, Array, null],
      default: '',
    },
    config: {
      type: Object,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: 'Select Date',
    },
  },
  data() {
    return { date: this.$props.value }
  },
  computed: {
    propsDate() {
      return this.$props.value
    },
  },
  watch: {
    propsDate() {
      this.$nextTick(() => {
        if (this.date !== this.propsDate) {
          this.date = this.propsDate
        }
      })
    },
  },
  methods: {
    onChange(data, dateStr) {
      if (this.$props?.config?.mode === 'range') {
        if (data.length === 2) {
          if (JSON.stringify(data[0]) === JSON.stringify(data[1])) {
            const preparedDate = `${this.date} to ${this.date}`
            this.$emit('input', preparedDate)
          } else {
            this.$emit('input', this.date)
          }
        }
      } else {
        this.$emit('input', dateStr)
      }
    },
    onDateClear() {
      this.date = ''
      this.$emit('input', this.date)
    },
  },
}
</script>
