<template>
  <b-form
    @submit.prevent="submitHandler"
  >
    <b-row>
      <b-col cols="12">
        <b-alert
          variant="danger"
          :show="errorMessage ? true : false"
        >
          <div
            v-if="typeof errorMessage === 'string'"
            class="alert-body"
          >
            <p>
              {{ errorMessage }}
            </p>
          </div>
          <div
            v-else-if="errorMessage.detail"
            class="alert-body"
          >
            <p>
              {{ errorMessage.detail }}
            </p>
          </div>
          <div
            v-else-if="errorMessage.non_field_errors"
            class="alert-body"
          >
            <p>
              {{ errorMessage.non_field_errors[0] }}
            </p>
          </div>
          <div
            v-else-if="errorMessage.message"
            class="alert-body"
          >
            <p>
              {{ errorMessage.message }}
            </p>
          </div>
        </b-alert>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="PDF File"
          label-for="pdf-file"
        >
          <b-form-file
            id="pdf-file"
            v-model="pdfFile"
            accept=".pdf"
          />
          <p
            v-if="errorMessage && errorMessage.pdf_file"
            class="error-message"
          >
            {{ errorMessage.pdf_file[0] }}
          </p>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <label for="datepicker-full-width">CN Date of Marg</label>
        <b-form-datepicker
          id="datepicker-full-width"
          v-model="cnDateOfMarg"
          menu-class="w-100"
          calendar-width="100%"
        />
        <p
          v-if="errorMessage && errorMessage.cn_date_of_marg"
          class="error-message"
        >
          {{ errorMessage.cn_date_of_marg[0] }}
        </p>
      </b-col>

      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="CN Number of Company Document"
          label-for="cn-number-of-company-document"
        >
          <b-form-input
            id="cn-number-of-company-document"
            v-model="cnNumberOfCompanyDocument"
            type="text"
            placeholder="Enter CN Number of Company Document"
          />
          <p
            v-if="errorMessage && errorMessage.cn_number_of_company_document"
            class="error-message"
          >
            {{ errorMessage.cn_number_of_company_document[0] }}
          </p>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="CN Number of issue Marg"
          label-for="cn-number-of-issue-marg"
        >
          <b-form-input
            id="cn-number-of-issue-marg"
            v-model="cnNumberOfIssueMarg"
            type="text"
            placeholder="Enter CN Number of issue Marg"
          />
          <p
            v-if="errorMessage && errorMessage.cn_number_of_issue_marg"
            class="error-message"
          >
            {{ errorMessage.cn_number_of_issue_marg[0] }}
          </p>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <label for="datepicker-full-width-1">CN Date of Company</label>
        <b-form-datepicker
          id="datepicker-full-width-1"
          v-model="cnDateOfCompany"
          menu-class="w-100"
          calendar-width="100%"
        />
        <p
          v-if="errorMessage && errorMessage.cn_date_of_company"
          class="error-message"
        >
          {{ errorMessage.cn_date_of_company[0] }}
        </p>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="CN Items Count"
          label-for="cn-items"
        >
          <b-form-input
            id="cn-items"
            v-model="cnItemsCount"
            type="number"
            placeholder="CN Items Count"
          />
          <p
            v-if="errorMessage && errorMessage.cn_items_count"
            class="error-message"
          >
            {{ errorMessage.cn_items_count[0] }}
          </p>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="CN Amount"
          label-for="amount"
        >
          <b-form-input
            id="amount"
            v-model="cnAmount"
            type="number"
            placeholder="CN Amount"
          />
          <p
            v-if="errorMessage && errorMessage.cn_amount"
            class="error-message"
          >
            {{ errorMessage.cn_amount[0] }}
          </p>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <label for="textarea-default">Remarks</label>
        <b-form-textarea
          id="textarea-default"
          v-model="remarks"
          rows="3"
        />
        <p
          v-if="errorMessage && errorMessage.remarks"
          class="error-message"
        >
          {{ errorMessage.remarks[0] }}
        </p>
      </b-col>
    </b-row>
    <b-button
      class="mt-1 mr-1"
      variant="secondary"
      @click="cancel()"
    >
      Cancel
    </b-button>
    <b-button
      variant="primary"
      :disabled="saving"
      class="mt-1 mr-1"
      type="submit"
    >
      Create
      <b-spinner
        v-if="saving"
        small
        label="Small Spinner"
      />
    </b-button>
  </b-form>

</template>

<script>
import {
  BRow, BCol, BFormGroup, BButton, BFormInput, BFormTextarea, BFormDatepicker, BFormFile, BForm, BSpinner, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormFile,
    BFormGroup,
    BFormInput,
    BForm,
    BFormDatepicker,
    BFormTextarea,
    BButton,
    BSpinner,
    BAlert,
  },
  props: {
    supplierReturnId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      pdfFile: null,
      cnDateOfMarg: null,
      cnNumberOfCompanyDocument: null,
      cnNumberOfIssueMarg: null,
      cnDateOfCompany: null,
      cnItemsCount: null,
      cnAmount: null,
      remarks: null,
      saving: false,
      errorMessage: {},
    }
  },
  methods: {
    submitHandler(event) {
      event.preventDefault()
      const formData = new FormData()
      formData.append('supplier_return', this.supplierReturnId)

      if (this.pdfFile) {
        formData.append('pdf_file', this.pdfFile)
      }
      if (this.cnDateOfMarg) {
        formData.append('cn_date_of_marg', this.cnDateOfMarg)
      }
      if (this.cnNumberOfCompanyDocument) {
        formData.append('cn_number_of_company_document', this.cnNumberOfCompanyDocument)
      }
      if (this.cnNumberOfIssueMarg) {
        formData.append('cn_number_of_issue_marg', this.cnNumberOfIssueMarg)
      }
      if (this.cnDateOfCompany) {
        formData.append('cn_date_of_company', this.cnDateOfCompany)
      }
      if (this.cnItemsCount) {
        formData.append('cn_items_count', this.cnItemsCount)
      }
      if (this.cnAmount) {
        formData.append('cn_amount', this.cnAmount)
      }
      if (this.remarks) {
        formData.append('remarks', this.remarks)
      }
      this.saving = true
      axios.post('/returns/supplier_credit_note/', formData)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message || 'Supplier Credit Note Created',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.$emit('item-added')
          this.saving = false
        }).catch(error => {
          this.errorMessage = error?.response?.data || 'Something went wrong'
          this.saving = false
        })
    },
    cancel() {
      this.$emit('cancelled')
    },
  },
}

</script>

<style scoped>
.error-message {
  color: red
}
</style>
