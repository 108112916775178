<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      caption="Supplier Credit Note Details"
      caption-top
      @sort-changed="sortingChanged"
    >
      <template #cell(pdf_file)="data">
        <a
          v-if="data.item.pdf_file"
          :href="data.item.pdf_file"
          target="_blank"
        >
          <span>View File</span>
        </a>
      </template>
      <template #cell(cn_date_of_marg)="data">
        {{ data.item.cn_date_of_marg && formatedDate(data.item.cn_date_of_marg) }}
      </template>
      <template #cell(cn_date_of_company)="data">
        {{ data.item.cn_date_of_company && formatedDate(data.item.cn_date_of_company) }}
      </template>
      <template #cell(cn_amount)="data">
        {{ formateNumber(data.item.cn_amount) }}
      </template>
    </b-table>

    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => {currentPage = page}"
    />
  </div>

</template>

<script>
import {
  BSpinner, BTable, BAlert, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'
import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'
import DetailedPagination from '../UI/DetailedPagination.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BAlert,
    DetailedPagination,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    supplierReturnId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      results: [],
      tableFields: [
        { key: 'pdf_file', label: 'PDF File' },
        { key: 'cn_date_of_marg', label: 'CN Date of Marg', sortable: true },
        { key: 'cn_number_of_company_document', label: 'CN Number of Company Document', sortable: true },
        { key: 'cn_number_of_issue_marg', label: 'CN Number of issue Marg', sortable: true },
        { key: 'cn_date_of_company', label: 'CN Date of Company', sortable: true },
        { key: 'cn_items_count', label: 'CN Items Count', sortable: true },
        {
          key: 'cn_amount', label: 'CN Amount', sortable: true, class: 'text-right pr-2',
        },
        { key: 'remarks', label: 'Remarks' },
      ],
      sortBy: 'id',
      sortDesc: true,
      submitting: false,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    fetchItems() {
      this.loading = true
      axios.get('/returns/supplier_credit_note',
        {
          params: {
            sort_by: this.sortBy,
            sort_desc: this.sortDesc,
            page: this.currentPage,
            per_page: this.perPage,
            supplier_return: this.supplierReturnId,
          },
        })
        .then(res => {
          this.results = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        }).catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
  },
}
</script>
