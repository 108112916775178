<template>
  <span>
    <span>
      <feather-icon
        v-if="['Paid', 'true', 'Closed', 'YES'].includes(value)"
        icon="CheckCircleIcon"
        color="green"
      />
      <feather-icon
        v-else-if="['Not Paid', 'false', 'Open', 'NO'].includes(value)"
        icon="XCircleIcon"
        color="red"
      />
      <feather-icon
        v-else-if="['Partially Paid', 'Partially Closed', 'In Progress', 'N/A'].includes(value)"
        icon="AlertCircleIcon"
        color="#D2691E"
      />
      <feather-icon
        v-else-if="['Cancelled'].includes(value)"
        icon="CheckCircleIcon"
        color="blue"
      />
    </span>
    <span
      v-if="!hideLabel"
      class="ml-25"
    >
      {{ value }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
