<template>
  <b-row>
    <b-col md="2">
      <b-form-group
        label="Challan Number"
      >
        <b-form-input
          v-model="challanNumber"
          type="text"
          placeholder="Search Challan Number"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Challan Date Range"
      >
        <flat-picker
          v-model="challanDateRange"
          placeholder="Select Challan Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Company Group"
      >
        <custom-v-select
          v-model="selectedCompanyGroups"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="companyGroupOptions"
          label="text"
          placeholder="Select Company Groups"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Supplier"
      >
        <custom-v-select
          v-model="selectedSuppliers"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="supplierOptions"
          label="text"
          placeholder="Select Suppliers"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Retrun Type"
      >
        <custom-v-select
          v-model="selectedReturnTypeOption"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="returnTypeOptions"
          label="text"
          placeholder="Select Return Type"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Status"
      >
        <custom-v-select
          v-model="selectedStatusOption"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="statusOptions"
          label="text"
          placeholder="Select Status"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group label="Created By">
        <custom-v-select
          v-model="selectedUsers"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="usersList"
          placeholder="Select Created By"
          multiple
          :close-on-select="false"
          label="fullname"
          :reduce="(data => data.id)"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    CustomVSelect,
    FlatPicker,
    BFormInput,
  },
  data() {
    return {
      challanNumber: null,
      challanDateRange: null,
      companyGroupOptions: [],
      selectedCompanyGroups: [],
      supplierOptions: [],
      selectedSuppliers: [],
      returnTypeOptions: [
        { text: 'Breakage / Expiry', value: 'Breakage / Expiry' },
        { text: 'Purchase Return', value: 'Purchase Return' },
      ],
      selectedReturnTypeOption: null,
      statusOptions: [
        { text: 'Open', value: 'Open' },
        { text: 'Partially Closed', value: 'Partially Closed' },
        { text: 'Closed', value: 'Closed' },
      ],
      selectedStatusOption: null,
      usersList: [],
      selectedUsers: [],
    }
  },
  watch: {
    challanDateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/company_group_options')
        .then(res => {
          this.companyGroupOptions = res.data.data
        })

      axios.get('/supplier_options')
        .then(res => {
          this.supplierOptions = res.data.data
        })

      axios.get('/delivery/users_list')
        .then(res => {
          this.usersList = res.data
        })
    },
    filtersChangeHandler() {
      const filtersdata = {}
      if (this.challanNumber) {
        filtersdata.challan_number = this.challanNumber
      }
      if (this.challanDateRange) {
        const [dateFrom, dateTo] = this.challanDateRange.split(' to ')
        if (dateFrom && dateTo) {
          filtersdata.challan_date_from = dateFrom
          filtersdata.challan_date_to = dateTo
        }
      }
      if (this.selectedCompanyGroups.length > 0) {
        filtersdata.company_groups = this.selectedCompanyGroups.map(item => item.id)
      }
      if (this.selectedSuppliers.length > 0) {
        filtersdata.suppliers = this.selectedSuppliers.map(item => item.id)
      }
      if (this.selectedReturnTypeOption) {
        filtersdata.return_type = this.selectedReturnTypeOption.value
      }
      if (this.selectedStatusOption) {
        filtersdata.status = this.selectedStatusOption.value
      }
      if (this.selectedUsers) {
        filtersdata.created_by = this.selectedUsers
      }
      this.$emit('filtersUpdated', filtersdata)
    },
  },

}
</script>
