<template>
  <b-row v-if="totalItems > 0">
    <b-col>
      <b-pagination
        :value="currentPage"
        :size="size"
        :total-rows="totalItems"
        :per-page="perPage"
        @input="pageChangeHandler"
      />
    </b-col>
    <b-col
      align="right"
    >
      <p>
        Displying {{ startNo }} to {{ endNo }} records from total {{ totalItems }} records
      </p>
    </b-col>
  </b-row>

</template>

<script>
import {
  BPagination, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: { BRow, BPagination, BCol },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  computed: {
    startNo() {
      return ((this.currentPage - 1) * this.perPage) + 1
    },
    endNo() {
      return Math.min(this.startNo + (this.perPage - 1), this.totalItems)
    },
  },
  methods: {
    pageChangeHandler(page) {
      this.$emit('page-changed', page)
    },
  },
}

</script>
