<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template
      v-if="$attrs.multiple !== undefined"
      #list-header
    >
      <div class="d-flex custom-options">
        <div class="flex-grow-1">
          <b-button
            size="sm"
            variant="outline-primary"
            block
            @click="selectAll"
          >
            Select All
          </b-button>
        </div>
        <div class="flex-grow-1">
          <b-button
            size="sm"
            variant="outline-primary"
            block
            @click="clearAll"
          >
            Clear All
          </b-button>
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BButton,
  },
  methods: {
    selectAll() {
      let { options } = this.$attrs
      const { reduce } = this.$attrs
      if (reduce) {
        options = options.map(reduce)
      }
      this.$emit('input', options)
    },
    clearAll() {
      this.$emit('input', [])
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.custom-options {
  column-gap:10px;
  margin:10px;
}
</style>
